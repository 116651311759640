import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '../alert.service';
import { GService } from '../g.service';
import { GPostService, LooseObject, RespData } from '../gpost.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {

  constructor(private Service: GService, private GPost: GPostService, private Show: AlertService, private router: Router) { }
  formData: LooseObject = {};
  merchantList: any[] = [];
  pageList: any[] = [];
  accessControl: any = {};

  ngOnInit(): void {
    this.InitMerchants();
    this.InitPageList();
    try {
      this.accessControl = JSON.parse(this.formData.AccLevel);
    } catch { }
  }

  InitMerchants() {
    this.Show.Loading();
    this.formData.action = "MerchantList";
    this.formData.url = "api/Users";
    this.GPost.Post(this.formData).subscribe((resp) => {
      this.Show.Close();

      if (this.GPost.IsObject(resp)) {
        let data: RespData = resp as RespData;
        switch (data.status) {
          case "success":
            this.merchantList = this.Service.ToJsonObject(data.data);
            break;
          case "failed":
            this.Show.Error(data.message);
            break;
          default:
            this.Show.Alert("Invalid response received!");
            break;
        }
      }

    });
  }

  InitPageList() {
    this.Show.Loading();
    this.formData.action = "PageList";
    this.formData.url = "api/Users";
    this.GPost.Post(this.formData).subscribe((resp) => {
      this.Show.Close();

      if (this.GPost.IsObject(resp)) {
        let data: RespData = resp as RespData;
        switch (data.status) {
          case "success":
            this.pageList = this.Service.ToJsonObject(data.data);
            break;
          case "failed":
            this.Show.Error(data.message);
            break;
          default:
            this.Show.Alert("Invalid response received!");
            break;
        }
      }

    });
  }
  SaveDetails() {
    this.Show.Loading();
    this.formData.action = "AddUser";
    this.formData.url = "api/Users";
    this.formData.AccLevel = JSON.stringify(this.accessControl);
    this.GPost.Post(this.formData).subscribe((resp) => {
      this.Show.Close();

      if (this.GPost.IsObject(resp)) {
        let data: RespData = resp as RespData;
        switch (data.status) {
          case "success":
            this.Show.Success(data.message);
            this.Service.ReloadState(this.router);
            break;
          case "failed":
            this.Show.Alert(data.message);
            break;
          case "pending":
            break;
          default:
            this.Show.Alert("Invalid response received!");
            break;
        }
      }

    });
  }


}
