<div class="card card-box mb-5">
    <div class="card-header alert-danger bg-red-lights">
      <div class="font-size-xl white" style="float:left;">
        Pending Approvals
      </div>
    </div>
    
    <div class="divider"></div>
    <div class="card-body">
      <div class="form-row reportX mb-3">
        <div class="form-group col-md-3">
        From <input class="form-control" placeholder="yyyy-mm-dd"
                       name="from" [(ngModel)]="StartDate" (click)="d.toggle()" ngbDatepicker #d="ngbDatepicker">     
        </div>
        <div class="form-group col-md-3">
          To <input class="form-control" placeholder="yyyy-mm-dd"
                      name="to" [(ngModel)]="EndDate" (click)="dd.toggle()" ngbDatepicker #dd="ngbDatepicker">
        </div>
       
          <div class="form-group col-md-6">
            <br />
            <button (click)="GetTrans()" class="btn btn-danger" type="button">
              <span class="btn-wrapper--label">Generate Report</span>
              <span class="btn-wrapper--icon">
                <i class="fas fa-file-archive"></i>
              </span>
            </button>
          </div>
  
      </div>
  
      <div style="overflow-x:auto;">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-hover table-striped table-bordered report">
          <thead class="thead-light">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Activity</th>
              <th scope="col">User</th>
              <th scope="col">Comment</th>
              <th scope="col">Timestamp</th>   
              <th scope="col">Action</th>
                </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of transList">
              <td>{{item.sn}}</td>
              <td>{{item.Activity}}</td>
              <td>{{item.MakerID}}</td>
              <td>{{item.Narration}}</td>
              <td>{{item.MakerTS}}</td>
               <td>
                
                <div class="dropdown">
                  <button class="btn btn-xs btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Options
                  </button>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <button class="dropdown-item " >View Details</button>
                    <button class="dropdown-item "  (click)="ValidateTrans(item.ID)">Approve</button>
                    <button class="dropdown-item "  (click)="ValidateTrans(item.ID)">Reject</button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  