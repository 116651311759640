import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { LooseObject } from './gpost.service';

@Injectable({
  providedIn: 'root'
})
export class GService {

  constructor() { }
  IsObject(val: any) {
    if (val === null) { return false; }
    return ((typeof val === 'function') || (typeof val === 'object'));
  }
  ToArray(val: any) {
    let c: any[] = Array.of(val);
    return c[0];
  }
  ToJsonObject(val: string) {
    return JSON.parse(val);
  }
  ReloadState(router: Router) {
      router.routeReuseStrategy.shouldReuseRoute = () => false;
    router.onSameUrlNavigation = 'reload';
    router.navigate([router.url]);
  }
  SetLogin(data: any) {
    this.setData("LoginData", data);
  }
  GetLogin() {
    return JSON.parse(this.getData("LoginData") as string);
  }

  FormatDate(dt: any) {
    return dt.year + "-" + this.SetTo(2, dt.month) + "-" + this.SetTo(2, dt.day);
  }

  SetTo(num: number, itm: string) {
    let res:string = "";
    let counter: number = 0;
    let itmLen: number = (itm+"").length;
    num = num - itmLen;
    while (counter < num) {
      res = res + "0";
      counter++;
    }

    return res + itm;
  }
  setData(key: string, data: any) {
    const jsonData = JSON.stringify(data)
    localStorage.setItem(key, jsonData)
  }

  getData(key: string) {
    return localStorage.getItem(key)
  }

  removeData(key: string) {
    localStorage.removeItem(key)
  }

  ShowStatus(val: string) {
    let rsp: string = "";
    switch (val) {
      case "A":
        rsp = "Active";
        break;
      case "P":
        rsp = "Pending";
        break;
      case "Z":
        rsp = "Inactive";
        break;
    }

    return rsp;
  }
    ShowTransStatus(val: string) {
    let rsp: string = "";
    switch (val) {
      case "A":
        rsp = "Success";
        break;
      case "P":
        rsp = "Pending";
        break;
      case "Z":
        rsp = "Failed";
        break;
    }

    return rsp;
  }
  ShowStatusClass(val: string) {
    let rsp: string = "";
    switch (val) {
      case "A":
        rsp = "success";
        break;
      case "P":
        rsp = "warning";
        break;
      case "Z":
        rsp = "danger";
        break;
    }

    return rsp;
  }

}

//Authentication Guard
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private LoginService: GService,
    private TitleService: Title
  ) { }
  LoginData: LooseObject = new LooseObject();

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try {
      this.LoginData = this.LoginService.GetLogin() as LooseObject;
    } catch { }
    let AccLevel = JSON.parse(this.LoginData.AccLevel);
    let path: string = "";
    if (route.routeConfig != null) {
      path = route.routeConfig?.path as string;
    }
if(this.LoginData.UserID !="000001"){
  if(path.indexOf("ResetPassword")<0){
    if (AccLevel[path] !== true) {

      this.TitleService.setTitle("Welcome to Admin Portal");
      this.router.navigate(['/login']);
      return false;
    }
  }
}
  
   
    let ttl: string = "Dashboard - Admin Area";
    if (route.data != undefined && route.data.title != undefined) {
      ttl = route.data.title;
    }
    this.TitleService.setTitle(ttl);
      return true;
    
    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }


}
