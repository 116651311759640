import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bulk-trans',
  templateUrl: './bulk-trans.component.html',
  styleUrls: ['./bulk-trans.component.css']
})
export class BulkTransComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
