<div class="card card-box mb-5">
  <div class="card-header alert-danger bg-red-lights">
    <div class="font-size-xl white" style="float:left;">
      Create Merchant Account
    </div>
  </div>
  <div class="divider"></div>
  <div class="card-body">
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="inputEmail4">Institution Name</label>
        <input type="text" [(ngModel)]="formData.InstName" class="form-control" placeholder="Merchant Company">
      </div>
      <div class="form-group col-md-6">
        <label for="inputEmail4"> Institution Email</label>
        <input type="text" [(ngModel)]="formData.UEmail" class="form-control" placeholder="info@company.com">
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="inputEmail4">Admin Name</label>
        <input type="text" [(ngModel)]="formData.FullName" class="form-control" placeholder="Admin name">
      </div>
      <div class="form-group col-md-6">
        <label for="inputEmail4"> Admin Phone</label>
        <input type="text" [(ngModel)]="formData.CustPhone" class="form-control" placeholder="02XXXXXXXXXX">
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="inputEmail4">Daily Transaction Limit</label>
        <input type="text" [(ngModel)]="formData.DailyLimit" class="form-control" placeholder="5000.00">
      </div>
      <div class="form-group col-md-6">
        <label for="inputEmail4"> Max. Single Transaction Limit</label>
        <input type="text" [(ngModel)]="formData.TransLimit" class="form-control" placeholder="500.00">
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="inputEmail4">Business Activity</label>
        <textarea [(ngModel)]="formData.ShortDesc" class="form-control"></textarea>
      </div>
      <div class="form-group col-md-6">
        <label for="inputEmail4">Allowed IP Address (Note: Comma separated)</label>
        <textarea [(ngModel)]="formData.IPAddress" class="form-control"></textarea>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="inputEmail4">Transaction Charge (Payout)</label>
        <div class="input-group">
          <div class="input-group-prepend" style=" width:200px;"><span class="input-group-text">%</span> <input class="form-control" placeholder="0.00" [(ngModel)]="formData.PPCharge" type="number"></div>
          <div class="input-group-prepend" style=" width:200px;"><span class="input-group-text">Flat</span> <input class="form-control" placeholder="0.00" [(ngModel)]="formData.PFCharge" type="number"></div>        
          <div class="input-group-prepend" style=" width:200px;"><span class="input-group-text">Cap</span> <input class="form-control" placeholder="0.00" [(ngModel)]="formData.PCap" type="number"></div>        
      </div>
    </div>
      <div class="form-group col-md-6">
        <label for="inputEmail4">Transaction Charge (Collections)</label>
        <div class="input-group">
          <div class="input-group-prepend" style=" width:200px;"><span class="input-group-text">%</span> <input class="form-control" placeholder="0.00" [(ngModel)]="formData.CPCharge" type="number"></div>
          <div class="input-group-prepend" style=" width:200px;"><span class="input-group-text">Flat</span> <input class="form-control" placeholder="0.00" [(ngModel)]="formData.CFCharge" type="number"></div>        
          <div class="input-group-prepend" style=" width:200px;"><span class="input-group-text">Cap</span> <input class="form-control" placeholder="0.00" [(ngModel)]="formData.CCap" type="number"></div>        
      </div>
      </div>
      
    </div>

    <div class="form-row">
      <button (click)="SaveMerchant()" class="btn btn-danger" type="button">
        <span class="btn-wrapper--label">Save Merchant</span>
        <span class="btn-wrapper--icon">
          <i class="fas fa-file-archive"></i>
        </span>
      </button>

    </div>

  </div>
</div>
