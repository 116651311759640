import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../alert.service';
import { EditMerchantComponent } from '../edit-merchant/edit-merchant.component';
import { GService } from '../g.service';
import { GPostService, LooseObject, RespData } from '../gpost.service';
import { TopupAccountComponent } from '../topup-account/topup-account.component';
import { WithdrawFundsComponent } from '../withdraw-funds/withdraw-funds.component';
@Component({
  selector: 'app-merchant-list',
  templateUrl: './merchant-list.component.html',
  styleUrls: ['./merchant-list.component.css']
})
export class MerchantListComponent implements OnInit {

  constructor(private GPost: GPostService, private Show: AlertService, private ModalService: NgbModal, private Service: GService) {

  }
  dtOptions: any = {};
  //dtTrigger: Subject<any> = new Subject();
  //dtElement: DataTableDirective | undefined;

  formData: LooseObject = {};
  userList: any[] = [];
  serviceList: any[] = [];

  LoginData: LooseObject = {};
  AccControl: LooseObject = {};

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      lengthMenu: [10, 50, 100],
      dom: "Bfrtip",
      buttons: ["excel", "print"]
    };
    this.GetUsers();
    this.LoginData = this.Service.GetLogin() as LooseObject;
    this.AccControl = JSON.parse(this.LoginData.AccLevel);

  }
  EditUser(val: any) {
    const modal = this.ModalService.open(EditMerchantComponent, { size: "lg" });
    modal.componentInstance.user = val;
  }
  TopupAccount(val: any) {
    const modal = this.ModalService.open(TopupAccountComponent, { size: "lg" });
    modal.componentInstance.user = val;
  }
  WithdrawAccount(val: any) {
    const modal = this.ModalService.open(WithdrawFundsComponent, { size: "lg" });
    modal.componentInstance.user = val;
  }
  ShowStatus(val: string) {
    return this.Service.ShowStatus(val);
  }
  ShowStatusClass(val: string) {
    return this.Service.ShowStatusClass(val);
  }
  ResetMKey(val: any) {
    this.Show.Loading();
    this.formData.action = "ResetMKey";
    this.formData.url = "api/Users";
    this.formData.ID = val.ID;
    this.formData.UserIDX = val.UserIDX;
    this.GPost.Post(this.formData).subscribe((resp) => {
      this.Show.Close();

      if (this.GPost.IsObject(resp)) {
        let data: RespData = resp as RespData;
        switch (data.status) {
          case "success":
            this.Show.Success(data.message);
            break;
          case "failed":
            this.Show.Alert(data.message);
            break;
          case "pending":
            break;
          default:
            this.Show.Alert("Invalid response received!");
            break;
        }
      }

    });
  }
  
  GetUsers() {
    this.Show.Loading();
    this.formData.action = "GetMerchants";
    this.formData.url = "api/Users";
    this.GPost.Post(this.formData).subscribe((resp) => {
      this.Show.Close();

      if (this.GPost.IsObject(resp)) {
        let data: RespData = resp as RespData;
        switch (data.status) {
          case "success":
            this.userList = this.Service.ToJsonObject(data.data);
            break;
          case "failed":
            this.Show.Error(data.message);
            break;
          default:
            this.Show.Alert("Invalid response received!");
            break;
        }
      }

    });
  }

}
