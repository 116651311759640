<div class="card card-box mb-5">
  <div class="card-header alert-danger bg-red-lights">
    <div class="font-size-xl white" style="float:left;">
     API Merchant List
    </div>
    <div class="btn btn-secondary" (click)="GetUsers()" style="float:right;">
      Refresh
    </div>
  </div>
  <div class="divider"></div>
  <div class="card-body">
    <div style="overflow-x:auto;">
      <table *ngIf="userList.length" datatable [dtOptions]="dtOptions" class="table table-hover table-striped table-bordered mb-5">
        <thead class="thead-light">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Admin Name</th>
            <th scope="col">Institution</th>
            <th scope="col">Phone Number</th>
            <th scope="col">Email Address</th>
            <th scope="col">Daily Limit</th>
            <th scope="col">Trans Limit</th>
            <th scope="col">SMS Balance</th>
            <th scope="col">Balance</th>
            <th scope="col">Status</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of userList">
            <td>{{item.sn}}</td>
            <td>{{item.FullName}}</td>
            <td>{{item.InstName}}</td>
            <td>{{item.CustPhone}}</td>
            <td>{{item.UEmail}}</td>
            <td>{{item.DailyLimit}}</td>
            <td>{{item.TransLimit}}</td>
            <td>{{item.SMS}}</td>
            <td>{{item.Balance}}</td>
            <td><span class="badge badge-pill m-1 badge-{{ShowStatusClass(item.Statuz)}}">{{ShowStatus(item.Statuz)}}</span></td>
            <td>
              <div class="dropdown">
                <button class="btn btn-xs btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Options
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <button class="dropdown-item " (click)="EditUser(item)">Edit Merchant</button>
                  <button class="dropdown-item " (click)="ResetMKey(item)">Reset API Token</button>
                  <button *ngIf="AccControl['AddFunds']" class="dropdown-item" (click)="TopupAccount(item)">Topup Account</button>
                  <button *ngIf="AccControl['AddFunds']" class="dropdown-item" (click)="WithdrawAccount(item)">Withdraw Funds</button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
