import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import {RespData, LooseObject, GPostService} from '../../gpost.service';
import { AlertService } from '../../alert.service';
import { GService } from '../../g.service';

@Component({
  selector: 'app-audit-trail',
  templateUrl: './audit-trail.component.html',
  styleUrls: ['./audit-trail.component.css']
})

export class AuditTrailComponent implements AfterViewInit, OnDestroy,OnInit {
  @ViewChild(DataTableDirective)
  dtElement!: DataTableDirective;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  formData: LooseObject = {};
  transList: any[] = [];
  merchantList: any[] = [];
  array  : LooseObject = {};
  StartDate: any = {};
  EndDate: any = {};

  constructor(private Show: AlertService, private GPost: GPostService, private Service: GService) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      lengthMenu: [10, 50, 100],
      dom: "Bfrtip"
    };
    this.GetTrans();
  }

  rerender(): void {
    try {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
        // Call the dtTrigger to rerender again
        this.dtTrigger.next();
      });
    } catch (err) {
      console.log(err);
    }
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  GetTrans() {
    this.Show.Loading();
    this.formData.action = "GetApprovals";
    this.formData.url = "api/Trans";
    let dt = this.StartDate;
    if (dt != undefined && dt.year != undefined) {
      this.formData.StartDate = this.Service.FormatDate(dt);
    }
    dt = this.EndDate;
    if (dt != undefined && dt.year != undefined) {
      this.formData.EndDate = this.Service.FormatDate(dt);
    }
  
    this.GPost.Post(this.formData).subscribe((resp) => {
    this.Show.Close();
  
      if (this.GPost.IsObject(resp)) {
        let data: RespData = resp as RespData;
        switch (data.status) {
          case "success":
            this.transList = this.Service.ToJsonObject(data.data);
            this.rerender();
            break;
          case "failed":
            this.Show.Error(data.message);
            break;
          default:
            this.Show.Alert("Invalid response received!");
            break;
        }
      }
  
    });
  
    }

ValidateTrans(TransID: string) {
  this.Show.Loading();
  this.formData.action = "ValidateTrans";
  this.formData.url = "api/Trans";
 this.formData.TransID = TransID;

  this.GPost.Post(this.formData).subscribe((resp) => {
  this.Show.Close();

    if (this.GPost.IsObject(resp)) {
      let data: RespData = resp as RespData;
      switch (data.status) {
        case "success":
          this.Show.Success(data.message);           
          break;
        case "failed":
          this.Show.Error(data.message);
          break; 
          case "pending":
          this.Show.Alert(data.message);
          break;
        default:
          this.Show.Alert("Invalid response received!");
          break;
      }
    }

  });

  }

  ShowStatus(val: string) {
    return this.Service.ShowTransStatus(val);
  }
  ShowStatusClass(val: string) {
    return this.Service.ShowStatusClass(val);
  }

}
