import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardDetailsComponent } from './dashboard-details/dashboard-details.component';
import { UserListComponent } from './user-list/user-list.component';
import { TransHistoryComponent } from './reports/trans-history/trans-history.component';
import { SettingsComponent } from './settings/settings.component';
import {SignupComponent} from './signlog/signup/signup.component';
import { LoginComponent} from './signlog/login/login.component';
import { AddMerchantComponent } from './add-merchant/add-merchant.component';
import { MerchantListComponent } from './merchant-list/merchant-list.component';
import { AddUserComponent } from './add-user/add-user.component';
import { AuthGuard } from './g.service';
import { ForgotPasswordComponent } from './signlog/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './signlog/reset-password/reset-password.component';
import { SetupPaymentRulesComponent } from './setup-payment-rules/setup-payment-rules.component';
import { FlaggedTransactionsComponent } from './reports/flagged-transactions/flagged-transactions.component';
import { RemittanceHistoryComponent } from './reports/remittance-history/remittance-history.component';
import { PendingApprovalsComponent } from './reports/pending-approvals/pending-approvals.component';
import { AuditTrailComponent } from './reports/audit-trail/audit-trail.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, data: { title: "Login - Admin Area" }},
  { path: 'forgot-password', component: ForgotPasswordComponent, data: { title: "Forgot Password - Admin Area" }},
  {
    path: 'Dashboard', component: DashboardComponent,
    children: [
      { path: '', component: DashboardDetailsComponent, data: {title:"Dashboard - Admin Area"} },
      { path: 'AddMerchant', component: AddMerchantComponent, data: { title: "Add Merchant - Admin Area" }, canActivate: [AuthGuard] },
      { path: 'MerchantList', component: MerchantListComponent, data: { title: "Merchant List" }, canActivate: [AuthGuard] },
      { path: 'UserList', component: UserListComponent, data: { title: "User List" }, canActivate: [AuthGuard]  },
      { path: 'AddUser', component: AddUserComponent, data: { title: "Add Application User" }, canActivate: [AuthGuard]  },
      { path: 'TransHistory', component: TransHistoryComponent, data: { title: "Transaction History" }, canActivate: [AuthGuard]  },
      { path: 'ResetPassword', component: ResetPasswordComponent, data: { title: "Reset Password" }, canActivate: [AuthGuard]  },
      { path: 'PaymentRules', component: SetupPaymentRulesComponent, data: { title: "Pyament Rules - Admin Area" }, canActivate: [AuthGuard]  },
      { path: 'FlaggedTransactions', component: FlaggedTransactionsComponent, data: { title: "Flagged Transactions - Admin Area" }, canActivate: [AuthGuard]  },
      { path: 'RemittanceTransactions', component: RemittanceHistoryComponent, data: { title: "Pending Approvals - Admin Area" }, canActivate: [AuthGuard]  },
      { path: 'PendingApprovals', component: PendingApprovalsComponent, data: { title: "Audit Trail - Admin Area" }, canActivate: [AuthGuard]  },
      { path: 'AuditTrail', component: AuditTrailComponent, data: { title: "Transactions Remittance - Admin Area" }, canActivate: [AuthGuard]  },
      { path: 'Settings', component: SettingsComponent, data: { title: "Application Settings - Admin Area" }, canActivate: [AuthGuard]  }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
