
<div class="app-wrapper">
  <div class="app-sidebar  app-sidebar--dark dark-red ">
    <div class="app-sidebar--header">
      <div class="nav-logo w-100 text-center">
        <a href="./index.html" class="d-block" style="color:#FFF;" data-toggle="tooltip" title="Admin Dashboard">
          <img src="./assets/img/admin-logo.png" alt="">
       
        </a>
      </div>
    </div>

    

    <div class="app-sidebar--content scrollbar-container" >
      <div class="sidebar-navigation">
        <ul id="sidebar-nav">
          <li class="mm-active">
            <a routerLink="/Dashboard" aria-expanded="true">
              <span>
                <i class="fas fa-tachometer-alt"></i>
                <span>Dashboard</span>
              </span>
              <i class="fas fa-chevron-right"></i>
            </a>
          </li>
          <li class="sidebar-header">
            Menu
          </li>
          <li *ngIf="AccControl['AddMerchant']">
            <a routerLink="./AddMerchant">
              <span>
                <i class="fas fa-fill-drip"></i>
                <span>Add Merchant</span>
              </span>
              <i class="fas fa-chevron-right"></i>
            </a>
          </li>
          <li *ngIf="AccControl['MerchantList']">
            <a routerLink="./MerchantList">
              <span>
                <i class="fas fa-car-battery"></i>
                <span>List Merchants</span>
              </span>
              <i class="fas fa-chevron-right"></i>
            </a>
          </li>
          <li *ngIf="AccControl['AddUser']">
            <a routerLink="./AddUser">
              <span>
                <i class="fas fa-shuttle-van"></i>
                <span>Add User</span>
              </span>
              <i class="fas fa-chevron-right"></i>
            </a>
          </li>
          <li *ngIf="AccControl['UserList']">
            <a routerLink="./UserList">
              <span>
                <i class="fas fa-shuttle-van"></i>
                <span>List Users</span>
              </span>
              <i class="fas fa-chevron-right"></i>
            </a>
          </li>
          <li *ngIf="AccControl['BulkTrans']">
            <a routerLink="./BulkTrans">
              <span>
                <i class="fas fa-shuttle-van"></i>
                <span>Bulk Transaction</span>
              </span>
              <i class="fas fa-chevron-right"></i>
            </a>
          </li>
          <li  *ngIf="AccControl['TransHistory']">
            <a routerLink="./TransHistory">
              <span>
                <i class="fas fa-space-shuttle"></i>
                <span>Transaction History</span>
              </span>
              <i class="fas fa-chevron-right"></i>
            </a>
          </li>
          <li  *ngIf="AccControl['RemittanceTransactions']">
            <a routerLink="./RemittanceTransactions">
              <span>
                <i class="fas fa-space-shuttle"></i>
                <span>Remittance Transactions</span>
              </span>
              <i class="fas fa-chevron-right"></i>
            </a>
          </li>    
          <li  *ngIf="AccControl['FlaggedTransactions']">
            <a routerLink="./FlaggedTransactions">
              <span>
                <i class="fas fa-space-shuttle"></i>
                <span>Flagged Transactions</span>
              </span>
              <i class="fas fa-chevron-right"></i>
            </a>
          </li>     
          <li  *ngIf="AccControl['PendingApprovals']">
            <a routerLink="./PendingApprovals">
              <span>
                <i class="fas fa-space-shuttle"></i>
                <span>Pending Approvals</span>
              </span>
              <i class="fas fa-chevron-right"></i>
            </a>
          </li>     
          <li  *ngIf="AccControl['AuditTrail']">
            <a routerLink="./AuditTrail">
              <span>
                <i class="fas fa-space-shuttle"></i>
                <span>Audit Trail</span>
              </span>
              <i class="fas fa-chevron-right"></i>
            </a>
          </li>     
          <li  *ngIf="AccControl['PaymentRules']">
            <a routerLink="./PaymentRules">
              <span>
                <i class="fas fa-space-shuttle"></i>
                <span>Setup Remittance Screening</span>
              </span>
              <i class="fas fa-chevron-right"></i>
            </a>
          </li>     
        </ul>
      </div>

    </div>
  </div>
  <div class="sidebar-mobile-overlay"></div>

  <div class="app-main">
    <div class="app-header">
      <div class="d-flex">
        <button class="navbar-toggler hamburger hamburger--elastic toggle-sidebar-mobile" type="button">
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
        Last Login: {{LoginData.LastLogin}}
      </div>
      <div class="d-flex align-items-center">
        
        <div class="user-box dropdown ml-3">
          <a href="#" class="p-0 d-flex align-items-center" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <div class="d-block p-0">
              <img class="d-44 rounded" src="./assets/img/avatars/user_icon.png" alt="">
            </div>
            <div class="d-none d-md-block pl-2">
              <div class="font-weight-bold">
                {{LoginData.FirstName}} {{LoginData.LastName}}
              </div>
              <span class="text-black-50">
                Application User
              </span>
            </div>
            <span class="pl-3"><i class="fas fa-angle-down opacity-5"></i></span>
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" href="#">Edit Details</a>
            <a class="dropdown-item" routerLink="./ResetPassword">Change Password</a>
            <a class="dropdown-item btn btn-link-dark" routerLink="../login">Logout</a>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="search-modal" tabindex="-1" role="dialog" aria-labelledby="search-modal" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header bg-white d-block text-center">
            <h6 class="display-4 font-weight-bold">Search</h6>
            <p class="text-black-50 mb-0">Use the form below to search for files</p>
          </div>
          <div class="modal-body bg-light">
            <div class="p-5">
              <div class="input-group">
                <input type="search" class="form-control" placeholder="Search terms here..." />
                <div class="input-group-append">
                  <button class="btn btn-primary border-0" type="button">
                    <i class="fas fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer d-block text-center">
            <button type="button" class="btn btn-link btn-link-dark" data-dismiss="modal">Close search box</button>
          </div>
        </div>
      </div>
    </div>

    <div class="app-content">
      <div class="app-content--inner">

        <router-outlet></router-outlet>
        </div>
        <div class="app-footer font-size-sm text-black-50">
          <div>
            &copy; 2021 - Powered by <a href="https://dossempire.com" title="dossempire.com" target="_blank">Doss Empire</a>
          </div>
          <div>
            <ul class="nav nav-justified">
              <li class="nav-item">
                <a class="nav-link" href="#">Dashboard</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">License</a>
              </li>
              <li class="nav-item">
                <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Changelog</a>
              </li>
            </ul>
          </div>
        </div>

      </div>



    </div>
</div>

