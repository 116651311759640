<div class="card card-box mb-5">
    <div class="card-header alert-danger bg-red-lights">
      <div class="font-size-xl white" style="float:left;">
       Remittance Payment Rules
      </div>
    </div>
    <div class="divider"></div>
    <div class="card-body">
        <h3>AML Sanction List Configuration</h3>
      <div class="form-row">
        <div class="form-group col-md-4">
            <div class="custom-checkbox custom-control mb-3">
                <input class="custom-control-input" [(ngModel)]="formData.UNSanctions" name="UNSanctions" id="UNSanctions"  type="checkbox">
                <label class="custom-control-label" for="UNSanctions" >United Nations Sactions List</label>            
            </div>
        </div>
        <div class="form-group col-md-4">
            <div class="custom-checkbox custom-control mb-3">
                <input class="custom-control-input" [(ngModel)]="formData.USOFAC" name="USOFAC" id="USOFAC"  type="checkbox">
                <label class="custom-control-label" for="USOFAC" >US Department of Treasury OFAC</label>            
            </div>
        </div>
        <div class="form-group col-md-4">
            <div class="custom-checkbox custom-control mb-3">
                <input class="custom-control-input" [(ngModel)]="formData.UKHM" name="UKHM" id="UKHM"  type="checkbox">
                <label class="custom-control-label" for="UKHM" >UK HM Treasury Office Sanction List</label>            
            </div>
        </div>
    </div>

    <h3>Transaction Limits Configuration</h3>
    <h5>Sender Configuration</h5>
    <div class="form-row">

        <div class="form-group col-md-4">
          <label for="inputEmail4"> Max Number of Transactions Per Day</label>
          <input type="text" [(ngModel)]="formData.STransNumPerDay" class="form-control" placeholder="5">
        </div>
        <div class="form-group col-md-4">
          <label for="inputEmail4"> Max Value of Transactions Per Day (GHS)</label>
          <input type="text" [(ngModel)]="formData.STransValPerDay" class="form-control" placeholder="100000.00">
        </div>
        <div class="form-group col-md-4">
          <label for="inputEmail4"> Max Monthly Moving Average (GHS)</label>
          <input type="text" [(ngModel)]="formData.STransAvgMonth" class="form-control" placeholder="100000.00">
        </div>
        
        <div class="form-group col-md-4">
            <div class="custom-checkbox custom-control mb-3">
                <input class="custom-control-input" [(ngModel)]="formData.SAIScore" name="SAIScore" id="SAIScore"  type="checkbox">
                <label class="custom-control-label" for="SAIScore" >Enable AI/Machine Learning Override</label>            
            </div>
        </div>
      </div>
      <h5>Beneficiary Configuration</h5>
      <div class="form-row">
  
          <div class="form-group col-md-4">
            <label for="inputEmail4"> Max Number of Transactions Per Day</label>
            <input type="text" [(ngModel)]="formData.BTransNumPerDay" class="form-control" placeholder="5">
          </div>
          <div class="form-group col-md-4">
            <label for="inputEmail4"> Max Value of Transactions Per Day (GHS)</label>
            <input type="text" [(ngModel)]="formData.BTransValPerDay" class="form-control" placeholder="100000.00">
          </div>
          <div class="form-group col-md-4">
            <label for="inputEmail4"> Max Monthly Moving Average (GHS)</label>
            <input type="text" [(ngModel)]="formData.BTransAvgMonth" class="form-control" placeholder="100000.00">
          </div>
          
          <div class="form-group col-md-4">
              <div class="custom-checkbox custom-control mb-3">
                  <input class="custom-control-input" [(ngModel)]="formData.BAIScore" name="BAIScore" id="SAIScore"  type="checkbox">
                  <label class="custom-control-label" for="BAIScore" >Enable AI/Machine Learning Override</label>            
              </div>
          </div>
        </div>
        <h3>Max. Single Transaction Limit</h3>
        <div class="form-row">

        <div class="form-group col-md-6">
            <input type="text" [(ngModel)]="formData.TransLimit" class="form-control" placeholder="500.00">
          </div>
      </div>
        
      <div class="form-row">
        <button (click)="SaveConfig()" class="btn btn-danger" type="button">
          <span class="btn-wrapper--label">Save Changes</span>
          <span class="btn-wrapper--icon">
            <i class="fas fa-file-archive"></i>
          </span>
        </button>
  
      </div>
  
    </div>
  </div>
  